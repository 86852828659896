<template>
  <div class="my-review">
    <header class="my-header">
      <div
        :class="['my-header-word', { active: communityIdsShow }]"
        @click="communityClick"
      >
        社区 <img src="../rubbishSort/img/垃圾分类切图/triangle.png" />
      </div>
      <div
        :class="['my-header-word', { active: villageIdShow }]"
        @click="villageIdClick"
      >
        小区 <img src="../rubbishSort/img/垃圾分类切图/triangle.png" />
      </div>
      <div class="my-header-word" @click="moreClick">
        更多 <img src="../rubbishSort/img/垃圾分类切图/triangle.png" />
      </div>
    </header>
    <div class="list-main">
      <v-refresh v-model="isLoading" @refresh="onRefresh">
        <v-list
          ref="list"
          :finished="finished"
          :finishedText="finishedText"
          @onLoad="getDataList"
        >
          <main class="my-main" v-for="(i, index) in configData" :key="index">
            <section class="main-header">
              <div class="img-wrapper">
                <img src="../rubbishSort/img/垃圾分类切图/home.png" alt="" />
                <div class="word1">
                  {{
                    i.communityName +
                    "-" +
                    i.villageName +
                    "-" +
                    i.building.match(/(\S*)幢/)[1] +
                    "-" +
                    i.houseId.match(/(\S*)B/)[1]
                  }}
                </div>
              </div>
              <div
                :class="[
                  'word2',
                  'pd' + evaluate(i.promiseAuditSts, i.quScore, i.actScore),
                ]"
              >
                {{
                  evaluateStatus[
                    evaluate(i.promiseAuditSts, i.quScore, i.actScore)
                  ]
                }}
              </div>
            </section>
            <section class="dr"></section>
            <section class="main-section">
              <div class="section-word1">承诺书审核</div>
              <div :class="['section-word2', 'cns' + i.promiseAuditSts]">
                {{ isSignPromiseObj[i.promiseAuditSts] }}
              </div>
            </section>
            <section class="main-section">
              <div class="section-word1">答题得分情况</div>
              <div :class="['section-word2', 'ty' + i.quScore]">
                {{ resultArrMap[i.quScore] }}
              </div>
            </section>
            <section class="main-section">
              <div class="section-word1">分类照片审核</div>
              <div
                :class="['section-word2', 'ty' + i.actScore]"
                @click="toPhoto(i.houseId, i.actUserId, i.actScore)"
              >
                {{ actDataListArrMap[i.actScore] }}
              </div>
            </section>
          </main>
        </v-list>
      </v-refresh>
    </div>
    <v-picker
      :value-show="communityIdsShow"
      :columns="communityIdsColumns"
      @cancel="communityIdsShow = false"
      @clickOverlay="communityIdsShow = false"
      @confirmPicker="confirmCommunity"
    ></v-picker>
    <v-picker
      :value-show="villageIdShow"
      :columns="villageIdColumns"
      @clickOverlay="villageIdShow = false"
      @cancel="villageIdShow = false"
      @confirmPicker="confirmVillageId"
    ></v-picker>
  </div>
</template>
<script>
import {
  communityIdsColumns,
  villageIdColumns,
  resultArrMap,
  actDataListArrMap,
  isSignPromiseObj,
  evaluateStatus,
} from "@/views/rubbishSort/map";
import { allStsDataListUrl } from "@/views/rubbishSort/api";

export default {
  data() {
    return {
      configData: [],
      communityIdsColumns,
      villageIdColumns,
      actDataListArrMap,
      isSignPromiseObj,
      resultArrMap,
      evaluateStatus,
      communityIdsShow: false,
      villageIdShow: false,
      communityIds: "",
      villageId: "",
      id: "",
      curPage: 1,
      pageSize: 10,
      isLoading: false,
      finished: false,
      finishedText: "没有更多了",
      num: null,
    };
  },
  computed: {
    searchData() {
      return this.$store.state.searchData;
    },
  },
  watch: {
    searchData: {
      handler() {
        this.getDataList();
      },
      deep: true,
    },
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {},
  methods: {
    communityClick() {
      this.communityIdsShow = !this.communityIdsShow;
    },
    villageIdClick() {
      this.villageIdShow = !this.villageIdShow;
    },
    moreClick() {
      this.$store.commit("SET_SEARCHDATA", {});
      this.$router.push({ name: "more" });
    },
    toPhoto(houseId, userId, actScore) {
      if (actScore === 0) {
        this.$router.push({
          name: "photoReview",
          query: {
            houseId: houseId,
            userId: userId,
            id: this.id,
          },
        });
      } else {
        this.$toast({ message: "已审核" });
      }
    },
    confirmCommunity(e) {
      this.communityIds = this.communityIdsColumns.indexOf(e);
      this.communityIdsShow = false;
      this.configData = [];
      this.getDataList();
    },
    confirmVillageId(e) {
      this.villageId = this.villageIdColumns.indexOf(e);
      this.villageIdShow = false;
      this.configData = [];
      this.getDataList();
    },
    getDataList() {
      this.$axios
        .get(allStsDataListUrl, {
          params: {
            topicId: this.id,
            // topicId: "1",
            curPage: this.curPage,
            pageSize: this.pageSize,
            communityId: this.communityIds,
            villageIds: this.villageId,
            ...this.searchData,
          },
        })
        .then((res) => {
          if (res.data.houseList) {
            if (this.curPage >= res.data.houseList.pages) {
              this.finished = true;
            }
          }
          if (
            Object.keys(res.data).length === 0 ||
            res.data.houseList.records.length === 0
          ) {
            this.configData = [];
            this.finishedText = "";
            return;
          }
          if (res.code === 200 && res.data && res.data.houseList.records) {
            if (this.curPage === 1) {
              this.configData = res.data.houseList.records;
            } else {
              const list = this.configData.map((i) => i.houseId);
              res.data.houseList.records.forEach((i) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(i.houseId) || this.configData.push(i);
              });
            }
            this.curPage++;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onRefresh() {
      this.getDataList();
    },
    evaluate(data1, data2, data3) {
      let arr = [data1.toString(), data2, data3];
      let num = null;
      //合格
      if (arr[1] === 3 && arr[2] === 3) {
        return (num = 3);
      }
      for (let i = 0; i < arr.length; i++) {
        //不合格
        if (arr[i] === 1) {
          return (num = 1);
        }
        //基本合格
        if (arr[i] === 2 && arr.indexOf(1) < 0) {
          return (num = 2);
        }
        //待评定
        if (arr[i] === 0 && arr.indexOf(1) < 0) {
          return (num = 0);
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.my-review {
  background: #f5f5f5;
  font-family: PingFangSC-Semibold, PingFang SC;
  .my-header {
    display: flex;
    align-items: center;
    background: #ffffff;
    .my-header-word {
      width: 33%;
      white-space: nowrap;
      text-align: center;
      padding: 28px;
      font-size: 28px;
      font-weight: 600;
      color: #1a1c34;
      animation: 1s infinite;
      > img {
        width: 15px;
        height: 12px;
      }
      &.active {
        > img {
          transform: rotate(180deg);
        }
      }
    }
  }
  .my-main {
    background: #ffffff;
    border-radius: 16px;
    margin: 20px 32px 0 32px;
    padding-bottom: 18px;
    .main-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 22px 28px 22px 22px;

      .img-wrapper {
        display: flex;
        align-items: center;

        > img {
          width: 40px;
          height: 40px;
        }

        .word1 {
          font-size: 28px;
          font-weight: 600;
          color: #1a1c34;
          padding-left: 12px;
        }
      }

      .word2 {
        font-size: 28px;
        font-weight: 600;

        &.pd0 {
          color: #2e5da2;
        }

        &.pd1 {
          color: #e50112;
        }

        &.pd2 {
          color: #f8cc5c;
        }

        &.pd3 {
          color: #40a739;
        }
      }
    }
    .dr {
      opacity: 0.17;
      border: 1px solid #979797;
      margin: 0 24px;
    }
    .main-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 28px 0 26px;
      .section-word1 {
        font-size: 28px;
        color: #1a1c34;
      }
      .section-word2 {
        font-size: 28px;
        color: #b0b1b4;
        &.ty1 {
          color: #e50112;
        }
        &.ty2 {
          color: #f8cc5c;
        }
        &.ty3 {
          color: #40a739;
        }
        &.cns1 {
          color: #40a739;
        }
        &.cns2 {
          color: #e50112;
        }
      }
    }
  }
}
.list-main {
  overflow: auto;
  height: calc(100vh - 96px);
}
</style>
